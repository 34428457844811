import axios from 'axios';

const API_BASE_URL = 'https://api.zhijiucity.com';

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const login = async (username, password) => {
  try {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    const response = await api.post('/api/v1/login', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDatasets = async () => {
  try {
    const response = await api.get('/api/datasets/getlist');
    return response.data;
  } catch (error) {
    console.error('Error fetching datasets:', error);
    throw error;
  }
};

export const getDatasetFiles = async (datasetName) => {
  try {
    const response = await api.get(`/api/datasets/${datasetName}/files`);
    return response.data;
  } catch (error) {
    console.error('Error fetching dataset files:', error);
    throw error;
  }
};

const imageFileTypes = ['image/jpeg', 'image/png', 'image/gif']; // 可根据需要添加其他图片类型

export const uploadImages = async (files, onUploadProgress, selectedLabelGroup) => {
  const formData = new FormData();
  
  for (const file of files) {
    if (imageFileTypes.includes(file.type)) {
      formData.append('files', file);
    }
  }
  formData.append('selected_label_group', selectedLabelGroup);
  try {
    const response = await api.post('/api/datasets/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading images:', error);
    throw error;
  }
};


export const getFile = async (datasetName, fileName) => {
  try {
    const response = await api.get(`/api/datasets/files/${datasetName}/${fileName}`, {
      responseType: 'blob'
    });
    return URL.createObjectURL(response.data);
  } catch (error) {
    console.error('Error fetching file:', error);
    throw error;
  }
};

export const updateImage = async (datasetName, fileName, formData) => {
  try {
    const response = await api.put(`/api/datasets/${datasetName}/${fileName}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating image:', error);
    throw error;
  }
};

export const deleteDataset = async (datasetName) => {
  try {
    const response = await api.delete(`/api/datasets/${datasetName}`);
    return response.data;
  } catch (error) {
    console.error('Error updating image:', error);
    throw error;
  }
};
