import axios from 'axios';

const API_BASE_URL = 'https://api.zhijiucity.com';

const api = axios.create({
  baseURL: API_BASE_URL,
});

// ------------------------------
// Organization Functions
// ------------------------------

// 创建组织
export const createOrganization = async (organizationData) => {
  return api.post('/back/organizations', organizationData);
};

// 获取单个组织信息
export const getOrganizationByUid = async (organizationUid) => {
  return api.get(`/back/organizations/${organizationUid}`);
};

// 更新组织信息
export const updateOrganization = async (organizationUid, updateData) => {
  return api.put(`/back/organizations/${organizationUid}`, updateData);
};

// 删除组织（软删除）
export const deleteOrganization = async (organizationUid) => {
  return api.delete(`/back/organizations/${organizationUid}`);
};

// 获取组织列表（分页）
export const getOrganizations = async ({ skip, limit }) => {
  return api.get('/back/organizations', { params: { skip, limit } });
};

// ------------------------------
// Organization Invite Functions
// ------------------------------

// 创建组织邀请
export const createOrganizationInvite = async (inviteData) => {
  return api.post('/back/organizations/invite', inviteData);
};

// 获取邀请信息（通过Token）
export const getOrganizationInviteByToken = async (token) => {
  return api.get(`/back/organizations/invite/${token}`);
};

// 更新组织邀请信息
export const updateOrganizationInvite = async (token, updateData) => {
  return api.put(`/back/organizations/invite/${token}`, updateData);
};

// ------------------------------
// Organization Membership Functions
// ------------------------------

// 添加组织成员
export const addOrganizationMember = async (membershipData) => {
  return api.post('/back/organizations/membership', membershipData);
};

// 获取组织成员列表（分页）
export const getOrganizationMembers = async ({ organizationUid, skip, limit }) => {
  return api.get(`/back/organizations/${organizationUid}/members`, {
    params: { skip, limit },
  });
};

// 删除组织成员
export const removeOrganizationMember = async (organizationUid, uuid) => {
  return api.delete(`/back/organizations/${organizationUid}/members/${uuid}`);
};

// ------------------------------
// Organization User Functions
// ------------------------------

// 添加组织管理员
export const addOrganizationAdmin = async (organizationUuid, adminData) => {
  return api.post(`/back/organizations/${organizationUuid}/admin`, adminData);
};

// 获取组织管理员
export const getOrganizationAdmin = async (organizationUuid) => {
  return api.get(`/back/organizations/${organizationUuid}/admin`);
};

// 更新组织管理员
export const updateOrganizationAdmin = async (userUuid, updateData) => {
  return api.put(`/back/organizations/admin/${userUuid}`, updateData);
};


// 删除组织管理员
export const removeOrganizationAdmin = async (userUuid) => {
  return api.delete(`/back/organizations/admin/${userUuid}`);
};

// ------------------------------
// Resource Group Functions
// ------------------------------

// 创建资源组
export const createResourceGroup = async (organizationUid, resourceData) => {
  return api.post(`/back/organizations/${organizationUid}/resources`, resourceData);
};

// 获取单个资源组信息
export const getResourceGroupById = async (resourceGroupId) => {
  return api.get(`/back/organizations/resources/${resourceGroupId}`);
};

// 获取组织的所有资源组（分页）
export const getResourceGroupsByOrganization = async ({ organizationUid, skip, limit }) => {
  return api.get(`/back/organizations/${organizationUid}/resources`, {
    params: { skip, limit },
  });
};

// 更新资源组信息
export const updateResourceGroup = async (resourceGroupId, updateData) => {
  return api.put(`/back/organizations/resources/${resourceGroupId}`, updateData);
};

// 删除资源组（软删除）
export const deleteResourceGroup = async (resourceGroupId) => {
  return api.delete(`/back/organizations/resources/${resourceGroupId}`);
};
