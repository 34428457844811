import axios from 'axios';

const API_BASE_URL = 'https://api.zhijiucity.com';

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const fetchPlans = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/back/plan/plan`);
    return response.data;
  } catch (error) {
    console.error("Error fetching plans:", error);
    throw error;
  }
};

// 创建新计划
export const createPlan = async (planData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/back/plan/plan`, planData);
    return response.data;
  } catch (error) {
    console.error("Error creating plan:", error);
    throw error;
  }
};

// 更新计划
export const updatePlan = async (planId, updatedData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/back/plan/plan/${planId}`, updatedData);
    return response.data;
  } catch (error) {
    console.error("Error updating plan:", error);
    throw error;
  }
};