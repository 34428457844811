import React, { useEffect, useState } from 'react';
import { getPaginatedImagesByRequest } from '@services/api_image_assets';
import './ImageAssets.css'; // Add this import for the CSS styles

const ImageAssets = () => {
  const [data, setData] = useState([]);
  const [prefix, setPrefix] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10); // Adjust as needed
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeImage, setActiveImage] = useState(null); // Track clicked image for lightbox

  useEffect(() => {
    const fetchPaginatedImages = async () => {
      setLoading(true);
      try {
        const response = await getPaginatedImagesByRequest(page, pageSize);
        // Filter out requests where user.status is "TEST"
        const filteredData = response.image_assets.filter(
          ([generationRequest]) => generationRequest.user.status !== 'TEST'
        );
        setData(filteredData);
        setPrefix(response.prefix);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchPaginatedImages();
  }, [page, pageSize]);

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleImageClick = (imagePath) => {
    setActiveImage(imagePath);
  };

  const handleCloseLightbox = () => {
    setActiveImage(null);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data: {error.message}</div>;

  return (
    <div className="image-assets-container">
      <h1 className="title">图片管理</h1>
      {data.map(([generationRequest, imageAssets]) => (
        <div key={generationRequest.id} className="request-card">
          <h2 className="request-id">Request ID: {generationRequest.id}</h2>
          <p className="prompt"><strong>Prompt:</strong> {generationRequest.prompt}</p>
          <p className="username"><strong>Username:</strong> {generationRequest.user.username}</p>
          <div className="image-grid">
            {imageAssets.map((imageAsset) => (
              <div key={imageAsset.id} className="image-card">
                <img
                  src={`${prefix}${imageAsset.save_path}`}
                  alt={`Image Asset ${imageAsset.id}`}
                  className="image-thumbnail"
                  onClick={() => handleImageClick(`${prefix}${imageAsset.save_path}`)} // Open image on click
                />
                <p className="seed">Seed: {imageAsset.seed}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={page === 1} className="pagination-button">
          Previous Page
        </button>
        <span className="page-number">Page {page}</span>
        <button onClick={handleNextPage} className="pagination-button">
          Next Page
        </button>
      </div>

      {/* Lightbox for image enlargement */}
      {activeImage && (
        <div className="lightbox" onClick={handleCloseLightbox}>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
            <img src={activeImage} alt="Enlarged" className="lightbox-image" />
            <button className="close-lightbox" onClick={handleCloseLightbox}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageAssets;
