// services/api_docs.js
import axios from 'axios';

const API_BASE_URL = 'https://api.zhijiucity.com';

const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000, // 设置请求超时时间（可选）
  headers: {
    'Content-Type': 'application/json',
    // 如果需要身份验证，可以在此添加 Authorization 头部
    // 'Authorization': `Bearer ${token}`,
  },
});

// 获取文档列表
export const fetchDocuments = async () => {
  try {
    const response = await api.get('/back/documents/documents/');
    return response.data.documents;
  } catch (error) {
    console.error('获取文档列表失败:', error);
    throw error;
  }
};

// 获取指定文档内容
export const fetchDocumentContent = async (name) => {
  try {
    const response = await api.get(`/back/documents/documents/${name}`);
    return response.data.content;
  } catch (error) {
    console.error(`获取文档 "${name}" 内容失败:`, error);
    throw error;
  }
};

// 保存或更新文档
export const saveDocument = async (name, content) => {
  try {
    const response = await api.post('/back/documents/documents/', { name, content });
    return response.data.message;
  } catch (error) {
    console.error(`保存文档 "${name}" 失败:`, error);
    throw error;
  }
};

export const fetchLogs = async () => {
  try {
    const response = await api.get('/back/logs/logs/');
    return response.data.logs;
  } catch (error) {
    console.error('获取日志列表失败:', error);
    throw error;
  }
};

// 获取指定日志内容
export const fetchLogContent = async (name) => {
  try {
    const response = await api.get(`/back/logs/logs/${name}`);
    return response.data.content;
  } catch (error) {
    console.error(`获取日志 "${name}" 内容失败:`, error);
    throw error;
  }
};

// 保存或更新日志
export const saveLog = async (name, content) => {
  try {
    const response = await api.post('/back/logs/logs/', { name, content });
    return response.data.message;
  } catch (error) {
    console.error(`保存日志 "${name}" 失败:`, error);
    throw error;
  }
};