import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '@assets/styles/Navbar.css';

const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  return (
    <div
      className={`navbar ${isExpanded ? 'expanded' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ul>
        <li><Link to="/">主页</Link></li>
        <li><Link to="/plan">计划</Link></li>
        <li><Link to="/backstage">管理</Link></li>
        <li><Link to="/tools">AI工具</Link></li>
        <li><Link to="/docs">文档</Link></li>
        <li><Link to="/test">测试</Link></li>
        <li><Link to="/git">版本控制</Link></li>
        <li><Link to="/contact">信息</Link></li>
      </ul>
    </div>
  );
};

export default Navbar;
