import React, { useEffect, useState } from 'react';
import {
  getResourceGroupsByOrganization,
  createResourceGroup,
  updateResourceGroup,
  deleteResourceGroup,
} from '@services/api_organization'; // 根据项目结构调整导入路径

const ResourceManager = ({ organizationUid, onUpdate, setNotification }) => {
  const [resources, setResources] = useState([]);
  const [formData, setFormData] = useState({
    resource_1: 0,
    resource_2: 0,
  });
  const [editingResourceId, setEditingResourceId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showResourceModal, setShowResourceModal] = useState(false);
  const limit = 10;
  const [skip, setSkip] = useState(0);

  // 获取资源组列表
  useEffect(() => {
    fetchResources();
  }, [skip]);

  const fetchResources = async () => {
    setLoading(true);
    try {
      const response = await getResourceGroupsByOrganization({
        organizationUid,
        skip,
        limit,
      });
      setResources(response.data.resources);
    } catch (error) {
      console.error('获取资源组失败:', error);
      setNotification({ message: '获取资源组列表失败。', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCreateResource = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await createResourceGroup(organizationUid, formData);
      setFormData({ resource_1: 0, resource_2: 0 });
      setShowResourceModal(false);
      setNotification({ message: '资源组创建成功!', type: 'success' });
      fetchResources();
      onUpdate(); // 通知父组件更新
    } catch (error) {
      console.error('创建资源组失败:', error);
      setNotification({ message: '创建资源组失败。', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateResource = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateResourceGroup(editingResourceId, formData);
      setEditingResourceId(null);
      setFormData({ resource_1: 0, resource_2: 0 });
      setShowResourceModal(false);
      setNotification({ message: '资源组更新成功!', type: 'success' });
      fetchResources();
      onUpdate(); // 通知父组件更新
    } catch (error) {
      console.error('更新资源组失败:', error);
      setNotification({ message: '更新资源组失败。', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (resource) => {
    setEditingResourceId(resource.resource_group_id);
    setFormData({
      resource_1: resource.resource_1,
      resource_2: resource.resource_2,
    });
    setShowResourceModal(true);
  };

  const handleDeleteClick = async (resourceGroupId) => {
    if (window.confirm('您确定要删除此资源组吗?')) {
      setLoading(true);
      try {
        await deleteResourceGroup(resourceGroupId);
        setNotification({ message: '资源组删除成功!', type: 'success' });
        fetchResources();
        onUpdate(); // 通知父组件更新
      } catch (error) {
        console.error('删除资源组失败:', error);
        setNotification({ message: '删除资源组失败。', type: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePrevPage = () => {
    if (skip >= limit) {
      setSkip(skip - limit);
    }
  };

  const handleNextPage = () => {
    setSkip(skip + limit);
  };

  const openCreateModal = () => {
    setEditingResourceId(null);
    setFormData({ resource_1: 0, resource_2: 0 });
    setShowResourceModal(true);
  };

  const handleCancelEdit = () => {
    setEditingResourceId(null);
    setFormData({ resource_1: 0, resource_2: 0 });
    setShowResourceModal(false);
  };

  return (
    <div className="resource-manager">
      <h3>资源组管理</h3>
      <button onClick={openCreateModal} className="create-button">
        创建资源组
      </button>

      {loading ? (
        <div className="loading">加载中...</div>
      ) : (
        <>
          <table className="resource-table">
            <thead>
              <tr>
                <th>资源组 ID</th>
                <th>SD处理单元</th>
                <th>超级模型处理单元</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {resources.length > 0 ? (
                resources.map((res) => (
                  <tr key={res.resource_group_id}>
                    <td>{res.resource_group_id}</td>
                    <td>{res.resource_1}</td>
                    <td>{res.resource_2}</td>
                    <td>
                      <button
                        onClick={() => handleEditClick(res)}
                        className="edit-button"
                      >
                        编辑
                      </button>
                      <button
                        onClick={() => handleDeleteClick(res.resource_group_id)}
                        className="delete-button"
                      >
                        删除
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="no-data">
                    未找到任何资源组。
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="pagination-buttons">
            <button onClick={handlePrevPage} disabled={skip === 0}>
              上一页
            </button>
            <button onClick={handleNextPage} disabled={resources.length < limit}>
              下一页
            </button>
          </div>
        </>
      )}

      {showResourceModal && (
        <div className="modal-overlay" onClick={handleCancelEdit}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>{editingResourceId ? '编辑资源组' : '创建资源组'}</h2>
            <form
              onSubmit={
                editingResourceId
                  ? handleUpdateResource
                  : handleCreateResource
              }
              className="resource-form"
            >
              <label>
                资源 1:
                <input
                  type="number"
                  name="resource_1"
                  value={formData.resource_1}
                  onChange={handleInputChange}
                  required
                  min="0"
                />
              </label>
              <label>
                资源 2:
                <input
                  type="number"
                  name="resource_2"
                  value={formData.resource_2}
                  onChange={handleInputChange}
                  required
                  min="0"
                />
              </label>
              <div className="form-buttons">
                <button
                  type="submit"
                  className="submit-button"
                  disabled={loading}
                >
                  {editingResourceId ? '更新' : '创建'}
                </button>
                <button
                  type="button"
                  onClick={handleCancelEdit}
                  className="cancel-button"
                >
                  取消
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResourceManager;
