import React, { useState, useEffect } from 'react';
import '@assets/styles/LabelSection.css';
import {
  getLabelGroups,
  getLabelData,
  addLabelGroup,
  addLabelCategory,
  addLabelSubcategory,
  updateImageLabel,
  getImageLabels,
  generateExcel,
} from '@services/labelApi';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const LabelSection = ({ labelGroup, currentImage, datasetName }) => {
  const [labelGroups, setLabelGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState('');
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [selectedLabels, setSelectedLabels] = useState({});
  const [newSubcategory, setNewSubcategory] = useState({ name: '', value: '' });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState('');

  useEffect(() => {
    const fetchLabelGroups = async () => {
      const data = await getLabelGroups();
      setLabelGroups(data.label_groups);
      if (labelGroup) {
        setCurrentGroup(labelGroup);
      } else if (data.label_groups.length > 0) {
        setCurrentGroup(data.label_groups[0]);
      }
    };
    fetchLabelGroups();
  }, [labelGroup]);

  useEffect(() => {
    if (currentGroup) {
      fetchCategories(currentGroup);
    }
  }, [currentGroup]);

  useEffect(() => {
    const fetchImageLabels = async () => {
      if (datasetName && currentImage) {
        const data = await getImageLabels(datasetName, currentImage);
        setSelectedLabels(data.labels || {});
      }
    };

    fetchImageLabels();
  }, [currentImage, datasetName]);

  const fetchCategories = async (group) => {
    const data = await getLabelData(group);
    setCategories(Object.entries(data));
  };

  const handleAddCategory = async () => {
    if (newCategory) {
      const updatedCategories = await addLabelCategory(currentGroup, newCategory);
      setCategories(Object.entries(updatedCategories));
      setNewCategory('');
    }
  };

  const openModal = (categoryName) => {
    setCurrentCategory(categoryName);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewSubcategory({ name: '', value: '' });
  };

  const handleAddSubcategory = async () => {
    if (newSubcategory.name && newSubcategory.value) {
      const updatedCategories = await addLabelSubcategory(
        currentGroup,
        currentCategory,
        newSubcategory.name,
        newSubcategory.value
      );
      setCategories(Object.entries(updatedCategories));
      closeModal();
    }
  };

  const handleGroupChange = (e) => {
    if (datasetName !== 'datasets') alert('暂不能切换，请返回数据集列表!');
    else setCurrentGroup(e.target.value);
  };

  const handleAddGroup = async () => {
    const groupName = prompt('请输入标签组名称:');
    if (groupName) {
      await addLabelGroup(groupName);
      const data = await getLabelGroups();
      setLabelGroups(data.label_groups);
      setCurrentGroup(groupName);
    }
  };

  const handleExportExcel = async () => {
    if (datasetName === 'datasets') {
      alert('请选择数据集！');
    } else {
      try {
        await generateExcel(datasetName, labelGroup);
      } catch (error) {
        console.error('Error generating Excel file:', error);
      }
    }
  };

  const handleLabelClick = async (category, subcategory) => {
    if (category === '描述') {
      // Do nothing for the "描述" category
      return;
    }

    const updatedLabels = { ...selectedLabels };

    if (
      category === '附属' ||
      category === '梁型' ||
      category === '结构形式' ||
      category === '装饰材料'
    ) {
      if (Array.isArray(updatedLabels[category])) {
        if (updatedLabels[category].includes(subcategory)) {
          updatedLabels[category] = updatedLabels[category].filter((item) => item !== subcategory);
          if (updatedLabels[category].length === 0) {
            delete updatedLabels[category];
          }
        } else {
          updatedLabels[category].push(subcategory);
        }
      } else {
        updatedLabels[category] = [subcategory];
      }
    } else {
      if (
        updatedLabels[category] &&
        Array.isArray(updatedLabels[category]) &&
        updatedLabels[category].includes(subcategory)
      ) {
        delete updatedLabels[category];
      } else {
        updatedLabels[category] = [subcategory];
      }
    }

    setSelectedLabels(updatedLabels);

    if (datasetName === 'datasets') {
      alert('请先选择数据集！');
    } else {
      await updateImageLabel({
        dataset: datasetName,
        image: currentImage,
        labels: updatedLabels,
      });
    }
  };

  const handleDescriptionChange = (value) => {
    setSelectedLabels((prevLabels) => ({
      ...prevLabels,
      描述: value,
    }));
  };

  const handleDescriptionBlur = async () => {
    if (datasetName === 'datasets') {
      alert('请先选择数据集！');
    } else {
      await updateImageLabel({
        dataset: datasetName,
        image: currentImage,
        labels: selectedLabels,
      });
    }
  };

  return (
    <div className="label-section">
      <div className="label-group-selector">
        <select value={currentGroup} onChange={handleGroupChange}>
          {labelGroups.map((group, index) => (
            <option key={index} value={group}>
              {group}
            </option>
          ))}
        </select>

        <button type="button" onClick={handleAddGroup}>
          添加标签组
        </button>
        <button type="button" onClick={handleExportExcel}>
          导出Excel
        </button>
      </div>

      {categories.map(([categoryName, subcategories], categoryIndex) => (
        <div key={categoryIndex} className="category-section">
          <div className="category-title">{categoryName}</div>
          <div className="subcategory-list">
            {Object.entries(subcategories).map(
              ([subcategoryName, subcategoryValue], subcategoryIndex) => {
                if (categoryName === '描述' && subcategoryName === '中文') {
                  return (
                    <input
                      key={subcategoryIndex}
                      type="text"
                      placeholder="请输入描述"
                      value={selectedLabels[categoryName] || ''}
                      onChange={(e) => handleDescriptionChange(e.target.value)}
                      onBlur={handleDescriptionBlur}
                    />
                  );
                } else {
                  const isSelected =
                    selectedLabels[categoryName] &&
                    Array.isArray(selectedLabels[categoryName]) &&
                    selectedLabels[categoryName].includes(subcategoryName);
                  return (
                    <button
                      key={subcategoryIndex}
                      className={`subcategory-item ${isSelected ? 'selected' : ''}`}
                      title={subcategoryValue}
                      onClick={() => handleLabelClick(categoryName, subcategoryName)}
                    >
                      {subcategoryName}
                    </button>
                  );
                }
              }
            )}
            <button type="button" onClick={() => openModal(categoryName)}>
              +
            </button>
          </div>
          {categoryIndex < categories.length - 1 && <div className="separator"></div>}
        </div>
      ))}
      <div className="add-category-section">
        <input
          type="text"
          placeholder="添加大类"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
        />
        <button type="button" onClick={handleAddCategory}>
          添加大类按钮
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="添加子类"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>添加子类</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            placeholder="子类名称"
            value={newSubcategory.name}
            onChange={(e) => setNewSubcategory({ ...newSubcategory, name: e.target.value })}
          />
          <input
            type="text"
            placeholder="子类值"
            value={newSubcategory.value}
            onChange={(e) => setNewSubcategory({ ...newSubcategory, value: e.target.value })}
          />
          <button type="button" onClick={handleAddSubcategory}>
            添加
          </button>
          <button type="button" onClick={closeModal}>
            取消
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default LabelSection;
