// @services/api_image_assets.js

import axios from 'axios';

const API_BASE_URL = 'https://api.zhijiucity.com';

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Function to get paginated images by request
export const getPaginatedImagesByRequest = async (page = 1, page_size = 10) => {
  try {
    const response = await api.get('/back/image_assets/paginated', {
      params: { page, page_size },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching paginated images by request:', error);
    throw error;
  }
};


// Function to get images by user UUID
export const getImagesByUserUUID = async (uuid) => {
  try {
    const response = await api.get(`/back/image_assets/user_uuid/${uuid}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching images by user UUID:', error);
    throw error;
  }
};

// Function to get images by request UUID
export const getImagesByRequestUUID = async (uuid) => {
  try {
    const response = await api.get(`/back/image_assets/request_uuid/${uuid}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching images by request UUID:', error);
    throw error;
  }
};

// Function to delete a generation request by UUID
export const deleteGenerationRequestById = async (request_id) => {
  try {
    const response = await api.delete(`/generation-request/${request_id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting generation request by UUID:', error);
    throw error;
  }
};

export const addImageAssetToHomeDisplayData = async (image_asset_uid) => {
    try {
      const response = await api.post(`/back/image_assets/home_display_data/${image_asset_uid}`);
      return response.data;
    } catch (error) {
      console.error('Error add:', error);
      throw error;
    }
};
  

export const getImageAssets = async (page = 1, page_size = 10) => {
    try {
      const response = await api.get('/back/image_assets/all_assets', {
        params: { page, page_size },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching paginated images by request:', error);
      throw error;
    }
  };


  // Add an image asset to Home Display Data
  export const uploadToHomeDisplayData = async (data) => {
    try {
      const formData = new FormData();
      formData.append('uuid', data.uuid);
      formData.append('username', data.username);
      formData.append('image_asset_uid', data.image_asset_uid);
      formData.append('favorites_counts', data.favorites_counts);
      formData.append('deleted', data.deleted);
      formData.append('model', data.model);
      formData.append('lora', data.lora);
      formData.append('seed', data.seed);
      formData.append('request_id', data.request_id);
      formData.append('fit', data.fit);
      if (data.feedback) {
        formData.append('feedback', data.feedback);
      }
      formData.append('img_base64', data.img_base64);
  
      const response = await api.post('/back/image_assets/upload_and_insert/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading data:', error);
      throw error;
    }
  };
  