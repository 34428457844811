import React, { useEffect, useState } from 'react';
import { Table, Statistic, Row, Col, Card, Button } from 'antd';
import { Area } from '@ant-design/plots';
import moment from 'moment';
import { getGenerationRequests } from '@services/api_backstage';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const GenerationRequestManagement = () => {
  const [generationRequests, setGenerationRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [chartData, setChartData] = useState([]);
  const [totalRequests, setTotalRequests] = useState(0);
  const [successfulRequests, setSuccessfulRequests] = useState(0);
  const [failedRequests, setFailedRequests] = useState(0);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const fetchGenerationRequests = async () => {
    setLoading(true);
    try {
      const response = await getGenerationRequests({
        skip: 0,
        limit: 10000,
      });
      const data = response.data;

      setGenerationRequests(data.generation_requests);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: data.total,
      }));

      setTotalRequests(data.total);

      const successCount = data.generation_requests.filter(
        (req) => req.status === 'success'
      ).length;
      const processingCount = data.generation_requests.filter(
        (req) => req.status === 'processing'
      ).length;
      setSuccessfulRequests(successCount);
      setFailedRequests(data.total - successCount - processingCount);

      const requestsByDate = {};

      data.generation_requests.forEach((req) => {
        const date = moment(req.created_at).format('YYYY-MM-DD');
        if (!requestsByDate[date]) {
          requestsByDate[date] = {
            date,
            total: 0,
            success: 0,
            failed: 0,
            processing: 0,
          };
        }
        requestsByDate[date].total += 1;
        if (req.status === 'success') {
          requestsByDate[date].success += 1;
        } else if (req.status === 'failed') {
          requestsByDate[date].failed += 1;
        } else if (req.status === 'processing') {
          requestsByDate[date].processing += 1;
        }
      });

      const chartData = Object.values(requestsByDate).sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      const stackedChartData = [];
      chartData.forEach((item) => {
        stackedChartData.push({
          date: item.date,
          type: '成功',
          count: item.success,
        });
        stackedChartData.push({
          date: item.date,
          type: '失败',
          count: item.failed,
        });
        stackedChartData.push({
          date: item.date,
          type: '处理中',
          count: item.processing,
        });
      });

      setChartData(stackedChartData);
    } catch (error) {
      console.error('Failed to fetch generation requests', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGenerationRequests();
  }, []);

  const columns = [
    {
      title: 'UUID',
      dataIndex: 'uuid',
      key: 'uuid',
      width: 100,
    },
    {
      title: '用户名',
      dataIndex: 'username',
      key: 'username',
      width: 30,
    },
    {
      title: '提示词',
      dataIndex: 'prompt',
      key: 'prompt',
      width: 100,
    },
    {
      title: '请求类型',
      dataIndex: 'request_type',
      key: 'request_type',
      width: 30,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span style={{ color: status === 'success' ? 'green' : 'red' }}>
          {status}
        </span>
      ),
      width: 30,
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (createdAt) => moment(createdAt).format('YYYY-MM-DD HH:mm'),
      width: 180,
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <div>
        <p><b>高清选项:</b> {record.hd_option ? '是' : '否'}</p>
        <p><b>生成宽度:</b> {record.width}</p>
        <p><b>生成高度:</b> {record.height}</p>
        <p><b>Lora:</b> {record.lora}</p>
        <p><b>负面提示词:</b> {record.negative_prompt || '无'}</p>
        <p><b>请求函数:</b> {record.request_func}</p>
        <p><b>强制任务ID:</b> {record.force_task_id}</p>
        <p><b>生成参数:</b> {record.parameters || '无'}</p>
      </div>
    );
  };

  const handleExportExcel = () => {
    const exportData = generationRequests.map((request) => ({
      UUID: request.uuid,
      用户名: request.username,
      提示词: request.prompt,
      请求类型: request.request_type,
      状态: request.status,
      创建时间: moment(request.created_at).format('YYYY-MM-DD HH:mm'),
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Requests');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'generation_requests.xlsx');
  };

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([record.id]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const chartConfig = {
    data: chartData,
    xField: 'date',
    yField: 'count',
    seriesField: 'type',
    isStack: true,
    smooth: true,
    tooltip: {
      showMarkers: false,
    },
    interactions: [{ type: 'active-region' }],
    legend: {
      position: 'top',
    },
  };

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={4}>
          <Card>
            <Statistic title="总请求数" value={totalRequests} />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic title="成功请求数" value={successfulRequests} />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic title="失败请求数" value={failedRequests} />
          </Card>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={handleExportExcel}>
            导出 Excel
          </Button>
        </Col>
      </Row>

      <div style={{ marginBottom: 20 }}>
        <Area {...chartConfig} style={{ height: 300 }} />
      </div>

      <Table
        columns={columns}
        dataSource={generationRequests}
        rowKey="id"
        loading={loading}
        expandable={{
          expandedRowRender,
          expandedRowKeys: expandedRowKeys,
          onExpand: handleExpand,
        }}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default GenerationRequestManagement;
