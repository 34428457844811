import React, { useState, useEffect } from 'react';
import { Modal, List, Card, Button, Spin, message, Pagination } from 'antd';
import { getImageAssets } from '@services/api_image_assets';

const { Meta } = Card;

const AddImageModal = ({ visible, onCancel, onAdd }) => {
  const [imageAssets, setImageAssets] = useState([]);
  const [imageAssetsLoading, setImageAssetsLoading] = useState(false);
  const [imageAssetsPrefix, setImageAssetsPrefix] = useState('');
  const [selectedImageAssetUid, setSelectedImageAssetUid] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8); // Set a default page size
  const [totalCount, setTotalCount] = useState(0); // Store total count of image assets

  useEffect(() => {
    if (visible) {
      fetchImageAssets(currentPage, pageSize);
    } else {
      setSelectedImageAssetUid(null);
    }
  }, [visible, currentPage, pageSize]);

  // Fetch image assets with pagination
  const fetchImageAssets = async (page, pageSize) => {
    setImageAssetsLoading(true);
    try {
      const response = await getImageAssets(page, pageSize);
      setImageAssets(response.image_assets);
      setImageAssetsPrefix(response.prefix);
      setTotalCount(response.total_count);  // Set total count for pagination
    } catch (error) {
      message.error('加载图片资产失败');
    } finally {
      setImageAssetsLoading(false);
    }
  };

  // Handle page change
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  // Submit selected image
  const handleSubmit = () => {
    if (selectedImageAssetUid) {
      onAdd(selectedImageAssetUid);
    } else {
      message.warning('请先选择一个图片资产');
    }
  };

  return (
    <Modal
      title="新增数据"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      {imageAssetsLoading ? (
        <Spin />
      ) : (
        <div>
          <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={imageAssets}
            renderItem={(item) => (
              <List.Item>
                <Card
                  hoverable
                  onClick={() => setSelectedImageAssetUid(item.image_asset_uid)}
                  style={{
                    border:
                      selectedImageAssetUid === item.image_asset_uid
                        ? '2px solid #1890ff'
                        : undefined,
                  }}
                  cover={
                    <img
                      alt="image asset"
                      src={`${imageAssetsPrefix}${item.thumbnails_save_path}`}
                      style={{ height: 150, objectFit: 'cover' }}
                    />
                  }
                >
                  <Meta
                    title={`ID: ${item.id}`}
                    description={`UUID: ${item.image_asset_uid}`}
                  />
                </Card>
              </List.Item>
            )}
          />

          {/* Pagination component */}
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalCount} // Use the total_count from the response
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={['8', '16', '32']} // Allow user to change the page size
            style={{ marginTop: 16 }}
          />

          {/* Submit button */}
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!selectedImageAssetUid}
            style={{ marginTop: 16 }}
          >
            提交
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default AddImageModal;
