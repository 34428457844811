import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Space, Form, Popconfirm, Select } from 'antd';
import { getAllMembershipViews, updateMembershipView, deleteMembershipView } from '@services/api_backstage';

const MembershipManagement = () => {
  const [memberships, setMemberships] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [editingUuid, setEditingUuid] = useState(null);
  const [searchUuid, setSearchUuid] = useState('');
  const [form] = Form.useForm();

  // Define membership type options for the dropdown
  const membershipTypes = [
    { label: '普通会员', value: 0 }, // 使用整数值
    { label: '一级会员', value: 1 },
    { label: '二级会员', value: 2 },
    { label: '三级会员', value: 3 },
    { label: '四级会员', value: 4 },
    { label: '五级会员', value: 5 },
    { label: '六级会员', value: 6 },
  ];

  // Fetch memberships
  const fetchMemberships = async () => {
    setLoading(true);
    try {
      const response = await getAllMembershipViews({
        skip: (pagination.current - 1) * pagination.pageSize,
        limit: pagination.pageSize,
        filter: searchUuid ? { user_uuid: searchUuid } : null, // Apply filter if UUID is provided
      });
      const { memberships: data, total } = response.data; // Assuming total count is included in the response

      setMemberships(data);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total, // Update total count
      }));
    } catch (error) {
      console.error('Failed to fetch memberships', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMemberships();
  }, [pagination.current, pagination.pageSize, searchUuid]);

  const isEditing = (record) => record.user_uuid === editingUuid;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingUuid(record.user_uuid);
  };

  const cancel = () => {
    setEditingUuid(null);
  };

  const save = async (uuid) => {
    try {
      const row = await form.validateFields();
      const transformedRow = {
        ...row,
        member_type: parseInt(row.member_type, 10), // 转换为整数
        remaining_quota: parseInt(row.remaining_quota, 10), // 转换为整数
      };
  
      const newMemberships = [...memberships];
      const index = newMemberships.findIndex((item) => uuid === item.user_uuid);
  
      if (index > -1) {
        const item = newMemberships[index];
        newMemberships.splice(index, 1, { ...item, ...transformedRow });
        setMemberships(newMemberships);
        setEditingUuid(null);
        await updateMembershipView(uuid, transformedRow); // 调用更新 API
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  

  const deleteMembership = async (uuid) => {
    try {
      await deleteMembershipView(uuid); // Call delete API
      setMemberships(memberships.filter((item) => item.user_uuid !== uuid));
    } catch (error) {
      console.error('Failed to delete membership', error);
    }
  };

  const columns = [
    {
      title: 'User UUID',
      dataIndex: 'user_uuid',
      key: 'user_uuid',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      editable: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      editable: true,
    },
    {
      title: 'Membership ID',
      dataIndex: 'membership_id',
      key: 'membership_id',
    },
    {
      title: 'Membership Type',
      dataIndex: 'member_type',
      key: 'member_type',
      editable: true,
      render: (text) => {
        const type = membershipTypes.find((item) => item.value === text);
        return type ? type.label : '';
      },
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Remaining Quota',
      dataIndex: 'remaining_quota',
      key: 'remaining_quota',
      editable: true,
    },
    {
      title: 'Allowed Devices',
      dataIndex: 'allowed_devices',
      key: 'allowed_devices',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button onClick={() => save(record.user_uuid)} type="link">
              Save
            </Button>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button type="link">Cancel</Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button type="link" disabled={editingUuid !== null} onClick={() => edit(record)}>
              Edit
            </Button>
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteMembership(record.user_uuid)}>
              <Button type="link" danger>
                Delete
              </Button>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'member_type' ? 'select' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          dataIndex === 'member_type' ? (
            <Form.Item
              name={dataIndex}
              style={{ margin: 0 }}
              rules={[{ required: true, message: `Please select ${title}!` }]}
            >
              <Select>
                {membershipTypes.map((type) => (
                  <Select.Option key={type.value} value={type.value}>
                    {type.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              name={dataIndex}
              style={{ margin: 0 }}
              rules={[{ required: true, message: `Please input ${title}!` }]}
            >
              <Input type={dataIndex === 'remaining_quota' ? 'number' : 'text'} /> {/* 设置为数字输入 */}
            </Form.Item>
          )
        ) : (
          children
        )}
      </td>
    );
  };

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const handleSearchChange = (e) => {
    setSearchUuid(e.target.value);
  };

  return (
    <Form form={form} component={false}>
      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search by UUID"
          value={searchUuid}
          onChange={handleSearchChange}
          style={{ width: 200, marginRight: 8 }}
        />
        <Button type="primary" onClick={fetchMemberships}>
          Search
        </Button>
      </div>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={memberships}
        rowKey="user_uuid"
        loading={loading}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={handleTableChange}
      />
    </Form>
  );
};

export default MembershipManagement;
