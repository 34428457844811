import React, { useState } from 'react';
import { Modal, Form, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { uploadToHomeDisplayData } from '@services/api_image_assets'; // Assuming you have this function
import { v4 as uuidv4 } from 'uuid';

const UploadImageAdd = ({ visible, onCancel, onAdd }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  // Handle file upload
  const handleChange = ({ fileList }) => {
    setFileList(fileList.slice(-1)); // Limit to one file
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const file = fileList[0]?.originFileObj;
      if (!file) {
        message.error('请上传一张图片');
        return;
      }

      // Read the file as base64
      const imgBase64 = await getBase64(file);

      // Generate fake data
      const data = {
        uuid: uuidv4(),
        username: 'fake_username',
        image_asset_uid: uuidv4(),
        favorites_counts: Math.floor(Math.random() * 100),
        deleted: false,
        model: 'fake_model',
        lora: 'fake_lora',
        seed: Math.floor(Math.random() * 100000),
        request_id: uuidv4(),
        fit: 'fake_fit',
        feedback: 'fake_feedback',
        img_base64: imgBase64,
      };

      // Call the API to upload data
      await uploadToHomeDisplayData(data);
      message.success('图片上传并新增成功');
      onAdd();
      form.resetFields();
      setFileList([]);
    } catch (error) {
      message.error('上传失败');
    } finally {
      setLoading(false);
    }
  };

  // Helper function to convert file to base64
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]); // Remove data:image/...;base64,
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <Modal
      visible={visible}
      title="上传图片新增"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          取消
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit} loading={loading}>
          提交
        </Button>,
      ]}
    >
      <Form form={form}>
        <Form.Item label="上传图片">
          <Upload
            accept="image/*"
            beforeUpload={() => false} // Prevent automatic upload
            onChange={handleChange}
            fileList={fileList}
          >
            <Button icon={<UploadOutlined />}>选择图片</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UploadImageAdd;
