import React, { useState } from 'react';
import '@assets/styles/Backstage.css';
import Label from '@components/Tools/Label';

const Tools = () => {
  const [activeTab, setActiveTab] = useState('标签');

  const renderContent = () => {
    switch (activeTab) {
      case '标签':
        return <Label />;
      case '训练日志':
        return null;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="tab-menu">
        <button onClick={() => setActiveTab('标签')} className={activeTab === '标签' ? 'active' : ''}>
        标签
        </button>
        <button onClick={() => setActiveTab('训练日志')} className={activeTab === '训练日志' ? 'active' : ''}>
          训练日志
        </button>
      </div>
      
      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default Tools;
