import React, { useEffect, useState } from "react";
import "./Dashboard.css"; // 引入 CSS 文件

const statusLabels = {
  new: "New",
  pending: "Pending",
  assigned: "Assigned",
  processing: "Processing",
  success: "Success",
  failed: "Failed",
  retrying: "Retrying",
  timeout: "Timeout",
};

const getStatusColor = (status) => {
  switch (status) {
    case "running":
      return "#4caf50"; // 绿色
    case "stopped":
      return "#f44336"; // 红色
    default:
      return "#ff9800"; // 橙色
  }
};

const Dashboard = () => {
  const [activateRequests, setActivateRequests] = useState([]);
  const [totalRequests, setTotalRequests] = useState(0);
  const [containers, setContainers] = useState([]);
  const [fluxProducers, setFluxProducers] = useState([]);

  useEffect(() => {
    const activateRequestsWS = new WebSocket("wss://backend.temp.fit/back/ws/activate_generations");
    const containersWS = new WebSocket("wss://backend.temp.fit/back/ws/containers");
    const fluxProducersWS = new WebSocket("wss://backend.temp.fit/back/ws/fluxProducers");

    activateRequestsWS.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.activate_generations) {
          setActivateRequests(data.activate_generations);
          setTotalRequests(data.total);
        }
      } catch (err) {
        console.error("Error parsing activate requests WebSocket message:", err);
      }
    };

    containersWS.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.containers) {
          setContainers(data.containers);
        }
      } catch (err) {
        console.error("Error parsing containers WebSocket message:", err);
      }
    };

    fluxProducersWS.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.fluxProducers) {
          setFluxProducers(data.fluxProducers);
        }
      } catch (err) {
        console.error("Error parsing flux producers WebSocket message:", err);
      }
    };

    return () => {
      activateRequestsWS.close();
      containersWS.close();
      fluxProducersWS.close();
    };
  }, []);

  const handleContainerAction = async (containerId, action) => {
    try {
      const response = await fetch(`/api/container/${action}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ container_id: containerId }),
      });
      if (!response.ok) {
        throw new Error(`Failed to ${action} container`);
      }
      alert(`Container ${action} successfully`);
    } catch (error) {
      console.error(`Error during ${action} container:`, error);
      alert(`Failed to ${action} container`);
    }
  };

  const handleProducerAction = async (producerId, action) => {
    try {
      const response = await fetch(`/api/producer/${action}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ producer_id: producerId }),
      });
      if (!response.ok) {
        throw new Error(`Failed to ${action} producer`);
      }
      alert(`Producer ${action} successfully`);
    } catch (error) {
      console.error(`Error during ${action} producer:`, error);
      alert(`Failed to ${action} producer`);
    }
  };

  return (
    <div className="dashboard">
      <h1 className="dashboard__title">Dashboard</h1>
      <div className="dashboard__main-content">
        <div className="dashboard__left-panel">
          {/* 左侧内容 */}
          <div className="dashboard__content">
            {/* Containers 部分 */}
            <section>
              <h2 className="dashboard__section-title">Containers</h2>
              <div className="container-list">
                {containers.map((container) => {
                  // 找到与此容器匹配的激活请求
                  const matchingRequests = activateRequests.filter(
                    (request) => request.container_id === container.container_id
                  );

                  return (
                    <div className="container-card" key={container.container_id}>
                      <div className="container-header">
                        <h3>Container {container.id}</h3>
                        {/* 状态图 */}
                        <div className="status-diagram">
                          <div
                            className={`status-circle ${container.status}`}
                            title={container.status}
                          ></div>
                        </div>
                        <div className="container-actions">
                          <div className="toggle-switch">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={container.status === "running"}
                                onChange={(e) =>
                                  handleContainerAction(
                                    container.container_id,
                                    e.target.checked ? "start" : "stop"
                                  )
                                }
                              />
                              <span className="slider round"></span>
                            </label>
                            <span className="toggle-label">
                              {container.status === "running" ? "Running" : "Stopped"}
                            </span>
                          </div>
                          <button
                            className="restart-button"
                            onClick={() => handleContainerAction(container.container_id, "restart")}
                          >
                            Restart
                          </button>
                        </div>
                      </div>
                      <div className="container-details">
                        <p>Container Id: {container.container_id}</p>
                        <p>Port: {container.port}</p>
                        <p>Device ID: {container.device_id}</p>
                        <p>Is Busy: {container.is_busy ? "Yes" : "No"}</p>

                        {/* 显示匹配的激活请求 */}
                        {matchingRequests.length > 0 && (
                          <div className="container-activate-requests">
                            <h4>Activate Requests</h4>
                            {matchingRequests.map((request) => (
                              <div key={request.id} className="activate-request">
                                {/* 生动显示请求状态 */}
                                <p>Request ID: {request.id}</p>
                                <div className="request-status-diagram">
                                  <div
                                    className={`status-circle ${request.status}`}
                                    title={statusLabels[request.status]}
                                  ></div>
                                  <p>Status: {statusLabels[request.status]}</p>
                                </div>
                                {/* 添加更多字段 */}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>

            {/* Flux Producers 部分 */}
            <section>
              <h2 className="dashboard__section-title">Flux Producers</h2>
              <div className="producer-list">
                {fluxProducers.map((producer) => {
                  // 找到与此生产者匹配的激活请求
                  const matchingRequests = activateRequests.filter(
                    (request) => request.container_id === producer.container_id
                  );

                  return (
                    <div key={producer.uuid} className="producer-card">
                      <div className="producer-header">
                        <h3>Producer {producer.uuid}</h3>
                        {/* 状态图 */}
                        <div className="status-diagram">
                          <div
                            className={`status-circle ${producer.status}`}
                            title={producer.status}
                          ></div>
                        </div>
                      </div>
                      <div className="producer-details">
                        <p>Container Id: {producer.container_id}</p>
                        <p>Device ID: {producer.device_id}</p>
                        <p>Pipeline: {producer.pipeline}</p>
                        <p>Is Busy: {producer.is_busy ? "Yes" : "No"}</p>

                        {/* 显示匹配的激活请求 */}
                        {matchingRequests.length > 0 && (
                          <div className="producer-activate-requests">
                            <h4>Activate Requests</h4>
                            {matchingRequests.map((request) => (
                              <div key={request.id} className="activate-request">
                                {/* 生动显示请求状态 */}
                                <p>Request ID: {request.id}</p>
                                <div className="request-status-diagram">
                                  <div
                                    className={`status-circle ${request.status}`}
                                    title={statusLabels[request.status]}
                                  ></div>
                                  <p>Status: {statusLabels[request.status]}</p>
                                </div>
                                {/* 添加更多字段 */}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="producer-actions">
                        <button onClick={() => handleProducerAction(producer.uuid, "start")}>
                          Start
                        </button>
                        <button onClick={() => handleProducerAction(producer.uuid, "stop")}>
                          Stop
                        </button>
                        <button onClick={() => handleProducerAction(producer.uuid, "restart")}>
                          Restart
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          </div>
        </div>

        {/* 右侧面板显示正在排队的任务 */}
        <div className="dashboard__right-panel">
          <h2 className="dashboard__section-title">Queued Tasks</h2>
          <div className="queued-tasks-list">
            {activateRequests
              .filter((request) => request.status === "pending" || request.status === "new")
              .map((request) => (
                <div key={request.id} className="queued-task-card">
                  <p>Request ID: {request.id}</p>
                  <p>Status: {statusLabels[request.status]}</p>
                  {/* 显示其他请求详情 */}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
