import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from '@components/Navbar';
import Plan from '@pages/Plan';
import Home from '@pages/Home';
import Tools from '@pages/Tools';
import Contact from '@pages/Contact';
import Backstage from '@pages/Backstage';
import Login from '@components/Login';
import MarkdownEditor from "@pages/MarkdownEditor"
import '@assets/styles/App.css';

const App = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    setIsLoggedIn(!!token);
  }, []);

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
    const token = localStorage.getItem('access_token');
    setIsLoggedIn(!!token);
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_info');
    setIsLoggedIn(false);
  };

  return (
    <div className="app">
      <Navbar />
      <div className="content">
        {isLoggedIn ? (
          <button className="login-button" onClick={handleLogout}>Logout</button>
        ) : (
          <button className="login-button" onClick={handleLoginClick}>Login</button>
        )}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/backstage" element={<Backstage />} />
          <Route path="/plan" element={<Plan />} />
          <Route path="/docs" element={<MarkdownEditor />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      {showLogin && <Login onClose={handleCloseLogin} />}
    </div>
  );
};

export default App;
