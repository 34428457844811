// components/LogViewer.js
import React, { useState, useEffect } from 'react';
import { fetchLogs, fetchLogContent } from '@services/api_docs';
import './LogViewer.css';
const LOG_LEVELS = ['DEBUG', 'INFO', 'WARNING', 'ERROR', 'CRITICAL'];

const LogViewer = () => {
  const [logs, setLogs] = useState([]); // 日志文件列表
  const [selectedLog, setSelectedLog] = useState(''); // 当前选择的日志文件
  const [logContent, setLogContent] = useState([]); // 原始日志内容（数组形式）
  const [filteredContent, setFilteredContent] = useState([]); // 过滤后的日志内容
  const [selectedLevels, setSelectedLevels] = useState(LOG_LEVELS); // 选择的日志级别
  const [loading, setLoading] = useState(false); // 加载状态
  const [error, setError] = useState(null); // 错误信息
  const [displayLines, setDisplayLines] = useState(1000); // 每次显示的行数
  const [hasMore, setHasMore] = useState(false); // 是否有更多行

  // 获取日志文件列表
  useEffect(() => {
    const getLogs = async () => {
      try {
        const logsList = await fetchLogs();
        console.log(logsList)
        setLogs(logsList);
      } catch (err) {
        setError('无法获取日志列表');
      }
    };
    getLogs();
  }, []);

  // 当选择的日志文件改变时，获取日志内容
  useEffect(() => {
    if (selectedLog) {
      const getLogContent = async () => {
        setLoading(true);
        setError(null);
        try {
          const content = await fetchLogContent(selectedLog);
          const lines = content.split('\n');
          setLogContent(lines);
          setHasMore(lines.length > displayLines);
          // 初始过滤
          filterContent(lines, selectedLevels, displayLines);
        } catch (err) {
          setError(`无法获取日志 "${selectedLog}" 的内容`);
        } finally {
          setLoading(false);
        }
      };
      getLogContent();
    }
  }, [selectedLog]);

  // 当选择的日志级别或显示的行数改变时，更新过滤内容
  useEffect(() => {
    if (logContent.length > 0) {
      filterContent(logContent, selectedLevels, displayLines);
    }
  }, [selectedLevels, displayLines]);

  // 过滤日志内容
  const filterContent = (lines, levels, maxLines) => {
    const filtered = lines.filter((line) => {
      for (let level of levels) {
        if (line.includes(`[${level}]`)) {
          return true;
        }
      }
      return false;
    });
    setFilteredContent(filtered.slice(0, maxLines));
    setHasMore(filtered.length > maxLines);
  };

  // 处理日志级别选择
  const handleLevelChange = (level) => {
    setSelectedLevels((prevLevels) => {
      if (prevLevels.includes(level)) {
        return prevLevels.filter((lvl) => lvl !== level);
      } else {
        return [...prevLevels, level];
      }
    });
  };

  // 加载更多行
  const loadMore = () => {
    setDisplayLines((prev) => prev + 1000);
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="log-viewer">
      <h1 className="log-viewer__title">CITY AI 日志</h1>
  
      {/* 日志文件列表 */}
      <div className="log-viewer__log-selector">
        <label
          htmlFor="log-select"
          className="log-viewer__log-selector-label"
        >
          选择日志文件：
        </label>
        <select
          id="log-select"
          value={selectedLog}
          onChange={(e) => {
            setSelectedLog(e.target.value);
            setDisplayLines(1000); // 重置显示的行数
          }}
          className="log-viewer__log-selector-dropdown"
        >
          <option value="">--请选择--</option>
          {logs.map((log) => (
            <option key={log} value={log}>
              {log}
            </option>
          ))}
        </select>
      </div>
  
      {/* 日志级别选择 */}
      <div className="log-viewer__log-levels">
        {LOG_LEVELS.map((level) => (
          <label
            key={level}
            className="log-viewer__log-level"
          >
            <input
              type="checkbox"
              checked={selectedLevels.includes(level)}
              onChange={() => handleLevelChange(level)}
            />
            {level}
          </label>
        ))}
      </div>
  
      {/* 日志内容显示 */}
      {loading ? (
        <div className="log-viewer__loading-message">加载中...</div>
      ) : (
        <div className="log-viewer__log-content">
          {filteredContent.map((line, index) => (
            <div key={index} className="log-viewer__log-line">
              {line}
            </div>
          ))}
          {hasMore && (
            <button
              onClick={loadMore}
              className="log-viewer__load-more"
            >
              加载更多
            </button>
          )}
        </div>
      )}
    </div>
  );
  
};

export default LogViewer;
