import React, { useEffect, useState } from 'react';
import {
  getHomeDisplayData,
  updateHomeDisplayData,
  deleteHomeDisplayData,
} from '@services/api_display';
import { addImageAssetToHomeDisplayData, uploadToHomeDisplayData } from '@services/api_image_assets';

import { Table, Button, Modal, Form, Input, message, Image, Popconfirm } from 'antd';

// Import the new UploadImageAdd component
import UploadImageAdd from './UploadImageAdd';
import AddImageModal from './AddImageModal';

const HomeDisplayDataManager = () => {
  // State management
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [editingItem, setEditingItem] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  // State for the new Upload Image Add feature
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  // Pagination state
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  // Load data
  const fetchData = async (page, pageSize) => {
    setLoading(true);
    try {
      const response = await getHomeDisplayData({ page, page_size: pageSize });
      setData(response.data.items);
      setTotalCount(response.data.total_count);
    } catch (error) {
      message.error('加载数据失败');
    } finally {
      setLoading(false);
    }
  };

  // Pagination change handler
  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  // Open edit modal
  const showEditModal = (item) => {
    setEditingItem(item);
    setIsEditModalVisible(true);
  };

  // Open add modal
  const showAddModal = () => {
    setIsAddModalVisible(true);
  };
  // Open upload modal
  const showUploadModal = () => {
    setIsUploadModalVisible(true);
  };

  // Close edit modal
  const closeEditModal = () => {
    setIsEditModalVisible(false);
    setEditingItem(null);
  };

  // Close upload modal
  const closeUploadModal = () => {
    setIsUploadModalVisible(false);
  };

  // Close add modal
  const closeAddModal = () => {
    setIsAddModalVisible(false);
  };
  // Handle update
  const handleUpdate = async (values) => {
    setLoading(true);
    try {
      await updateHomeDisplayData(editingItem.id, values);
      message.success('更新成功');
      fetchData(page, pageSize);
      closeEditModal();
    } catch (error) {
      message.error('更新失败');
    } finally {
      setLoading(false);
    }
  };

  // Handle delete
  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await deleteHomeDisplayData(id);
      message.success('删除成功');
      fetchData(page, pageSize);
    } catch (error) {
      message.error('删除失败');
    } finally {
      setLoading(false);
    }
  };

  // Handle after adding image
  const handleAfterAdd = () => {
    fetchData(page, pageSize);
    closeUploadModal();
  };
  const handleAddImage = async (imageAssetUid) => {
    setLoading(true);
    try {
      await addImageAssetToHomeDisplayData(imageAssetUid);
      message.success('新增成功');
      fetchData(page, pageSize);
      closeAddModal();
    } catch (error) {
      message.error('新增失败');
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    { title: 'UUID', dataIndex: 'uuid', key: 'uuid' },
    { title: '用户名', dataIndex: 'username', key: 'username' },
    { title: '收藏数', dataIndex: 'favorites_counts', key: 'favorites_counts' },
    { title: '创建时间', dataIndex: 'created_at', key: 'created_at' },
    {
      title: '图片',
      key: 'image',
      render: (_, record) => (
        <Image
          width={100}
          src={`https://api.zhijiucity.com/static/${record.thumbnails_save_path}`}
          alt="Thumbnail"
        />
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <>
          <Button onClick={() => showEditModal(record)} type="primary" style={{ marginRight: 8 }}>
            编辑
          </Button>
          <Popconfirm
            title="确定要删除这条数据吗？"
            onConfirm={() => handleDelete(record.id)}
            okText="是"
            cancelText="否"
          >
            <Button danger>删除</Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  // Initial data load
  useEffect(() => {
    fetchData(page, pageSize);
  }, [page, pageSize]);

  return (
    <div>
      <h2>首页展示图片管理</h2>

      {/* Add button */}
      <Button type="primary" onClick={showAddModal} style={{ marginBottom: 16 }}>
        已生成上传
      </Button>
      <Button type="primary" onClick={showUploadModal} style={{ marginBottom: 16 }}>
        本地上传
      </Button>

      {/* Data table */}
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        loading={loading}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: totalCount,
          onChange: handlePageChange,
        }}
      />

      {/* Edit Modal */}
      <Modal
        title="编辑数据"
        visible={isEditModalVisible}
        onCancel={closeEditModal}
        footer={null}
      >
        <Form initialValues={editingItem} onFinish={(values) => handleUpdate(values)}>
          <Form.Item
            label="UUID"
            name="uuid"
            rules={[{ required: true, message: '请输入 UUID' }]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="用户名"
            name="username"
            rules={[{ required: true, message: '请输入用户名' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="收藏数"
            name="favorites_counts"
            rules={[{ required: true, message: '请输入收藏数' }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label="创建时间"
            name="created_at"
            rules={[{ required: true, message: '请输入创建时间' }]}
          >
            <Input disabled />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading}>
            提交
          </Button>
        </Form>
      </Modal>

      {/* Upload Image Add Modal */}
      <AddImageModal
        visible={isAddModalVisible}
        onCancel={closeAddModal}
        onAdd={handleAddImage}
      />
      <UploadImageAdd
        visible={isUploadModalVisible}
        onCancel={closeUploadModal}
        onAdd={handleAfterAdd}
      />
    </div>
  );
};

export default HomeDisplayDataManager;
