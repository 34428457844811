import axios from 'axios';

const API_BASE_URL = 'https://api.zhijiucity.com';

const api = axios.create({
  baseURL: API_BASE_URL,
});

// 获取用户列表
export const getUsers = async ({ skip, limit, filter }) => {
  return api.get(`/back/user`, { params: { skip, limit, filter } });
};

// 更新用户信息
export const updateUser = async (userUUId, updateData) => {
  return api.put(`/back/user/${userUUId}`, updateData);
};

// ------------------------------
// 新的用户会员视图功能
// ------------------------------

// 创建会员信息视图
export const createMembershipView = async (membershipData) => {
  return api.post(`/back/membership`, membershipData);
};

// 获取单个用户的会员信息视图
export const getMembershipView = async (userUuid) => {
  return api.get(`/back/membership/${userUuid}`);
};

// 更新单个用户的会员信息视图
export const updateMembershipView = async (userUuid, updateData) => {
  return api.put(`/back/membership/${userUuid}`, updateData);
};

// 删除单个用户的会员信息视图
export const deleteMembershipView = async (userUuid) => {
  return api.delete(`/back/membership/${userUuid}`);
};

// 获取所有会员信息视图（分页）
export const getAllMembershipViews = async ({ skip, limit }) => {
  return api.get('/back/membership', { params: { skip, limit } });
};


// ------------------------------
// Generation Request Functions
// ------------------------------

// 获取生成请求列表（分页）
export const getGenerationRequests = async ({ skip, limit }) => {
  return api.get('/back/generation', {
    params: { skip, limit },
  });
};

// 获取单个生成请求详情
export const getGenerationRequestByUuid = async (uuid) => {
  return api.get(`/back/generation/${uuid}`);
};