// src/components/OrganizationManager.jsx

import React, { useEffect, useState } from 'react';
import {
  getOrganizations,
  createOrganization,
  updateOrganization,
  deleteOrganization,
} from '@services/api_organization'; // 根据项目结构调整导入路径
import AdminManager from './AdminManager'; // 导入 AdminManager 组件
import './OrganizationManager.css'; // 导入 CSS 样式
import ResourceManager from '@components/Backstage/OrganizationManager/ResourceManager.js';

const OrganizationManager = () => {
  const [organizations, setOrganizations] = useState([]);
  const [formData, setFormData] = useState({
    organization_uid: '',
    name: '',
    max_count: 0,
    o_groups: '',
    expired_at: '',
  });
  const [editingOrgUid, setEditingOrgUid] = useState(null);
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [expandedOrgUid, setExpandedOrgUid] = useState(null); // 跟踪展开的组织

  // 获取组织列表，每当 'skip' 变化时重新获取
  useEffect(() => {
    fetchOrganizations();
  }, [skip]);

  // 通知自动消失
  useEffect(() => {
    if (notification.message) {
      const timer = setTimeout(() => setNotification({ message: '', type: '' }), 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCreateOrganization = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await createOrganization({
        ...formData,
      });
      setFormData({
        organization_uid: '',
        name: '',
        max_count: 0,
        o_groups: '',
        expired_at: '',
      });
      setShowOrgModal(false);
      setNotification({ message: '组织创建成功!', type: 'success' });
      fetchOrganizations();
    } catch (error) {
      console.error('创建组织时出错:', error);
      setNotification({ message: '创建组织失败。', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateOrganization = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateOrganization(editingOrgUid, {
        ...formData,
      });
      setEditingOrgUid(null);
      setFormData({
        organization_uid: '',
        name: '',
        max_count: 0,
        o_groups: '',
        expired_at: '',
      });
      setShowOrgModal(false);
      setNotification({ message: '组织更新成功!', type: 'success' });
      fetchOrganizations();
    } catch (error) {
      console.error('更新组织时出错:', error);
      setNotification({ message: '更新组织失败。', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (organization) => {
    setEditingOrgUid(organization.organization_uid);
    setFormData({
      name: organization.name,
      max_count: organization.max_count,
      o_groups: organization.o_groups,
      expired_at: organization.expired_at ? new Date(organization.expired_at).toISOString().slice(0, 16) : '',
    });
    setShowOrgModal(true);
  };

  const handleDeleteClick = async (organization_uid) => {
    if (window.confirm('您确定要删除此组织吗?')) {
      setLoading(true);
      try {
        await deleteOrganization(organization_uid);
        setNotification({ message: '组织删除成功!', type: 'success' });
        fetchOrganizations();
      } catch (error) {
        console.error('删除组织时出错:', error);
        setNotification({ message: '删除组织失败。', type: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancelEdit = () => {
    setEditingOrgUid(null);
    setFormData({
      organization_uid: '',
      name: '',
      max_count: 0,
      o_groups: '',
      expired_at: '',
    });
    setShowOrgModal(false);
  };

  const handlePrevPage = () => {
    if (skip >= limit) {
      setSkip(skip - limit);
    }
  };

  const handleNextPage = () => {
    setSkip(skip + limit);
  };

  const openCreateModal = () => {
    setEditingOrgUid(null);
    setFormData({
      organization_uid: '',
      name: '',
      max_count: 0,
      o_groups: '',
      expired_at: '',
    });
    setShowOrgModal(true);
  };

  const fetchOrganizations = async () => {
    setLoading(true);
    try {
      const response = await getOrganizations({ skip, limit });
      const allOrganizations = response.data.organizations;
      setOrganizations(allOrganizations);
    } catch (error) {
      console.error('获取组织时出错:', error);
      setNotification({ message: '获取组织列表失败。', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const toggleExpand = (organization_uid) => {
    if (expandedOrgUid === organization_uid) {
      setExpandedOrgUid(null);
    } else {
      setExpandedOrgUid(organization_uid);
    }
  };

  return (
    <div className="organization-manager">
      <h1>组织管理</h1>

      <div className="top-bar">
        <button onClick={openCreateModal} className="create-button">
          创建组织
        </button>
      </div>

      {notification.message && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}

      {loading ? (
        <div className="loading">加载中...</div>
      ) : (
        <>
          <table className="organization-table">
            <thead>
              <tr>
                <th></th> {/* 展开按钮列 */}
                <th>UID</th>
                <th>名称</th>
                <th>最大数量</th>
                <th>分组</th>
                <th>创建时间</th>
                <th>过期时间</th>
                <th>状态</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {organizations.length > 0 ? (
                organizations.map((org) => (
                  <React.Fragment key={org.organization_uid}>
                    <tr className={org.level === -1 ? 'deleted' : ''}>
                      <td>
                        <button
                          onClick={() => toggleExpand(org.organization_uid)}
                          className="expand-button"
                        >
                          {expandedOrgUid === org.organization_uid ? '-' : '+'}
                        </button>
                      </td>
                      <td>{org.organization_uid}</td>
                      <td>{org.name}</td>
                      <td>{org.max_count}</td>
                      <td>{org.o_groups}</td>
                      <td>{new Date(org.created_at).toLocaleString()}</td>
                      <td>{org.expired_at ? new Date(org.expired_at).toLocaleString() : 'N/A'}</td>
                      <td>{org.level === -1 ? '已删除' : '正常'}</td>
                      <td>
                        {org.level !== -1 && (
                          <>
                            <button
                              onClick={() => handleEditClick(org)}
                              className="edit-button"
                            >
                              编辑
                            </button>
                            <button
                              onClick={() => handleDeleteClick(org.organization_uid)}
                              className="delete-button"
                            >
                              删除
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                    {expandedOrgUid === org.organization_uid && (
                      <tr className="admin-row">
                        <td colSpan="9">
                          <AdminManager
                            organization={org}
                            onUpdate={fetchOrganizations}
                            setNotification={setNotification}
                          />
                        </td>
                      </tr>
                    )}
                    {expandedOrgUid === org.organization_uid && (
                      <tr className="resource-row">
                        <td colSpan="9">
                          <ResourceManager
                            organizationUid={org.organization_uid}
                            onUpdate={fetchOrganizations}
                            setNotification={setNotification}
                          />
                        </td>
                      </tr>
                    )}

                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="no-data">
                    未找到任何组织。
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="pagination-buttons">
            <button onClick={handlePrevPage} disabled={skip === 0}>
              上一页
            </button>
            <button onClick={handleNextPage} disabled={organizations.length < limit}>
              下一页
            </button>
          </div>
        </>
      )}

      {/* 组织模态框 */}
      {showOrgModal && (
        <div className="modal-overlay" onClick={handleCancelEdit}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>{editingOrgUid ? '编辑组织' : '创建组织'}</h2>
            <form
              onSubmit={
                editingOrgUid
                  ? handleUpdateOrganization
                  : handleCreateOrganization
              }
              className="organization-form"
            >
              {!editingOrgUid && (
                <label>
                  组织 UID:
                  <input
                    type="text"
                    name="organization_uid"
                    value={formData.organization_uid}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              )}
              <label>
                名称:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                最大数量:
                <input
                  type="number"
                  name="max_count"
                  value={formData.max_count}
                  onChange={handleInputChange}
                  required
                  min="0"
                />
              </label>
              <label>
                分组:
                <input
                  type="text"
                  name="o_groups"
                  value={formData.o_groups}
                  onChange={handleInputChange}
                  required
                  placeholder="以逗号分隔的分组"
                />
              </label>
              <label>
                过期时间:
                <input
                  type="datetime-local"
                  name="expired_at"
                  value={formData.expired_at}
                  onChange={handleInputChange}
                />
              </label>
              <div className="form-buttons">
                <button
                  type="submit"
                  className="submit-button"
                  disabled={loading}
                >
                  {editingOrgUid ? '更新' : '创建'}
                </button>
                <button
                  type="button"
                  onClick={handleCancelEdit}
                  className="cancel-button"
                >
                  取消
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrganizationManager;
