import React, { useState, useEffect } from 'react';
import MDEditor from '@uiw/react-md-editor';
import { fetchDocuments, fetchDocumentContent, saveDocument } from '@services/api_docs';
import "@assets/styles/MarkdownEditor.css";

const AUTO_SAVE_DELAY = 2000; // 自动保存延迟 2 秒

const MarkdownEditor = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [content, setContent] = useState('');
  const [autoSaveTimeout, setAutoSaveTimeout] = useState(null);

  useEffect(() => {
    const loadDocuments = async () => {
      try {
        const docs = await fetchDocuments();
        setDocuments(docs);
      } catch (error) {
        console.error('加载文档列表失败:', error);
      }
    };

    loadDocuments();
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault();
        handleSave(); // Ctrl+S 保存
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [content, selectedDoc]);

  useEffect(() => {
    if (!selectedDoc) return;

    if (autoSaveTimeout) {
      clearTimeout(autoSaveTimeout);
    }

    const timeout = setTimeout(() => {
      handleSave(true); // 自动保存
    }, AUTO_SAVE_DELAY);

    setAutoSaveTimeout(timeout);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [content]);

  const handleDocumentSelect = async (name) => {
    try {
      const docContent = await fetchDocumentContent(name);
      setSelectedDoc(name);
      setContent(docContent);
    } catch (error) {
      console.error(`加载文档 "${name}" 内容失败:`, error);
    }
  };

  const handleSave = async (isAutoSave = false) => {
    if (!selectedDoc) {
      alert('请先选择一个文档。');
      return;
    }
    try {
      const message = await saveDocument(selectedDoc, content);
      if (!isAutoSave) {
      }
    } catch (error) {
      console.error(`保存文档 "${selectedDoc}" 失败:`, error);
      if (!isAutoSave) {
        alert('保存文档失败，请稍后重试。');
      }
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ width: '25%', borderRight: '1px solid #ccc', padding: '1rem' }}>
        <h2>文档列表</h2>
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {documents.map((doc) => (
            <li
              key={doc}
              style={{
                padding: '0.5rem',
                cursor: 'pointer',
                backgroundColor: selectedDoc === doc ? '#f0f0f0' : 'transparent',
              }}
              onClick={() => handleDocumentSelect(doc)}
            >
              {doc}
            </li>
          ))}
        </ul>
      </div>
      <div style={{ flex: 1, padding: '1rem' }}>
        {selectedDoc ? (
          <>
            <h2>编辑文档: {selectedDoc}</h2>
            <MDEditor
              value={content}
              onChange={setContent}
              height={500}
            />
            <button
              onClick={() => handleSave(false)}
              style={{
                marginTop: '1rem',
                padding: '0.5rem 1rem',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              保存
            </button>
          </>
        ) : (
          <p>请选择一个文档进行编辑。</p>
        )}
      </div>
    </div>
  );
};

export default MarkdownEditor;
