import axios from 'axios';

const API_BASE_URL = 'https://api.zhijiucity.com';

const api = axios.create({
  baseURL: API_BASE_URL,
});

// 获取用户列表
export const getHomeDisplayData = async ({ page = 1, page_size = 10 }) => {
    return api.get('/back/home_display_data/', {
      params: { page, page_size },
    });
  };
  
  // 获取单个 HomeDisplayData 详情
  export const getHomeDisplayDataById = async (id) => {
    return api.get(`/back/home_display_data/${id}`);
  };
  
  // 创建新的 HomeDisplayData
  export const createHomeDisplayData = async (data) => {
    return api.post('/back/home_display_data/', data);
  };
  
  // 更新 HomeDisplayData 信息
  export const updateHomeDisplayData = async (id, updateData) => {
    return api.put(`/back/home_display_data/${id}`, updateData);
  };
  
  // 删除 HomeDisplayData
  export const deleteHomeDisplayData = async (id) => {
    return api.delete(`/back/home_display_data/${id}`);
  };