import React, { useState } from 'react';
import '@assets/styles/Backstage.css';
import UserManagement from '@components/Backstage/UserManagement';
import MembershipManagement from '@components/Backstage/MembershipManagement';
import GenerationRequestManagement from '@components/Backstage/GenerationRequestManagement';
import LogViewer from '@components/Backstage/LogViewer';
import Dashboard from '@components/Backstage/Dashboard';
import OrganizationManager from '@components/Backstage/OrganizationManager/OrganizationManager';
import HomeDisplayDataManager from '@components/Backstage/HomeDisplayDataManager';
import ImageAssets from '@components/Backstage/ImageAssets';

const Backstage = () => {
  const [activeTab, setActiveTab] = useState('用户');

  const renderContent = () => {
    switch (activeTab) {
      case '任务队列':
        return <Dashboard />;
      case '用户':
        return <UserManagement />;
      case '会员':
        return <MembershipManagement />;
      case '生成记录':
        return <GenerationRequestManagement />;
      case '组织管理':
        return <OrganizationManager />;
      case '图片管理':
        return <ImageAssets />;
      case '首页展示':
        return <HomeDisplayDataManager />;
      case '日志':
        return <LogViewer />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="tab-menu">
        <button onClick={() => setActiveTab('任务队列')} className={activeTab === '任务队列' ? 'active' : ''}>
        任务队列
        </button>
        <button onClick={() => setActiveTab('用户')} className={activeTab === '用户' ? 'active' : ''}>
          用户
        </button>
        <button onClick={() => setActiveTab('会员')} className={activeTab === '会员' ? 'active' : ''}>
        会员
        </button>
        <button onClick={() => setActiveTab('生成记录')} className={activeTab === '生成记录' ? 'active' : ''}>
        生成记录
        </button>
        <button onClick={() => setActiveTab('组织管理')} className={activeTab === '组织管理' ? 'active' : ''}>
        组织管理
        </button>

        <button onClick={() => setActiveTab('首页展示')} className={activeTab === '首页展示' ? 'active' : ''}>
        首页展示
        </button>

        <button onClick={() => setActiveTab('图片管理')} className={activeTab === '图片管理' ? 'active' : ''}>
        图片管理
        </button>
        <button onClick={() => setActiveTab('日志')} className={activeTab === '日志' ? 'active' : ''}>
        日志
        </button>
        <button onClick={() => setActiveTab('反馈信息')} className={activeTab === '反馈信息' ? 'active' : ''}>
        反馈信息
        </button>
      </div>
      
      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default Backstage;
