import React, { useState, useEffect } from 'react';
import {
  addOrganizationAdmin,
  getOrganizationAdmin,
  removeOrganizationAdmin,
  updateOrganizationAdmin,
} from '@services/api_organization'; // 根据项目结构调整导入路径
import './AdminManager.css'; // 导入 AdminManager 专用的 CSS 样式

const AdminManager = ({ organization, onUpdate, setNotification }) => {
  const [admins, setAdmins] = useState([]); // 存储管理员信息数组
  const [loading, setLoading] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [adminData, setAdminData] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [editingAdmin, setEditingAdmin] = useState(null); // 跟踪正在编辑的管理员

  useEffect(() => {
    fetchAdmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAdmins = async () => {
    setLoading(true);
    try {
      const response = await getOrganizationAdmin(organization.organization_uid);
      setAdmins(response.data || []); // 确保返回的是数组
    } catch (error) {
      console.error('获取管理员信息时出错:', error);
      setNotification({ message: '获取管理员信息失败。', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleAddAdminClick = () => {
    setAdminData({
      username: '',
      email: '',
      password: '',
    });
    setEditingAdmin(null);
    setShowAdminModal(true);
  };

  const handleEditAdminClick = (admin) => {
    setAdminData({
      username: admin.username,
      email: admin.email,
      password: '', 
    });
    setEditingAdmin(admin);
    setShowAdminModal(true);
  };

  const handleDeleteAdminClick = async (adminUuid) => {
    if (window.confirm('您确定要移除该管理员吗?')) {
      setLoading(true);
      try {
        await removeOrganizationAdmin(adminUuid);
        setNotification({ message: '管理员已成功移除!', type: 'success' });
        fetchAdmins(); // 重新获取管理员信息
        onUpdate(); // 通知父组件更新
      } catch (error) {
        console.error('移除管理员时出错:', error);
        setNotification({ message: '移除管理员失败。', type: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmitAdmin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (editingAdmin) {
        // 使用 updateOrganizationAdmin 更新现有管理员信息
        await updateOrganizationAdmin(editingAdmin.uuid, adminData);
        setNotification({ message: '管理员更新成功!', type: 'success' });
      } else {
        // 添加新管理员
        await addOrganizationAdmin(organization.organization_uid, adminData);
        setNotification({ message: '管理员添加成功!', type: 'success' });
      }
      setShowAdminModal(false);
      fetchAdmins(); // 重新获取管理员信息
    } catch (error) {
      console.error('提交管理员信息时出错:', error);
      setNotification({ message: '提交管理员信息失败。', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-manager">
      {loading && <div className="loading">加载中...</div>}
      {!loading && (
        <>
          {admins.length > 0 ? (
            <div className="admin-list">
              {admins.map((admin) => (
                <div key={admin.uuid} className="admin-item">
                  <div>
                    <strong>用户名:</strong> {admin.username || '未知'}
                  </div>
                  <div>
                    <strong>邮箱:</strong> {admin.email || '未知'}
                  </div>
                  <div>
                    <strong>状态:</strong> {admin.status || '未知'}
                  </div>
                  <div>
                    <strong>UUID:</strong> {admin.uuid || '未知'}
                  </div>
                  <div>
                    <strong>创建时间:</strong>{' '}
                    {admin.created_at
                      ? new Date(admin.created_at).toLocaleString()
                      : '未知'}
                  </div>
                  <div className="admin-actions">
                    <button
                      onClick={() => handleEditAdminClick(admin)}
                      className="edit-admin-button"
                    >
                      编辑
                    </button>
                    <button
                      onClick={() => handleDeleteAdminClick(admin.uuid)}
                      className="remove-admin-button"
                    >
                      移除
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-admins">尚未分配管理员。</div>
          )}
          <button onClick={handleAddAdminClick} className="add-admin-button">
            添加管理员
          </button>
        </>
      )}

      {/* 管理员模态框 */}
      {showAdminModal && (
        <div className="modal-overlay" onClick={() => setShowAdminModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{editingAdmin ? '编辑管理员' : '添加管理员'}</h2>
            <form onSubmit={handleSubmitAdmin} className="admin-form">
              <label>
                用户名:
                <input
                  type="text"
                  name="username"
                  value={adminData.username}
                  onChange={(e) =>
                    setAdminData({ ...adminData, username: e.target.value })
                  }
                  required
                />
              </label>
              <label>
                邮箱:
                <input
                  type="email"
                  name="email"
                  value={adminData.email}
                  onChange={(e) =>
                    setAdminData({ ...adminData, email: e.target.value })
                  }
                  required
                />
              </label>
              <label>
                密码:
                <input
                  type="password"
                  name="password"
                  value={adminData.password}
                  onChange={(e) =>
                    setAdminData({ ...adminData, password: e.target.value })
                  }
                />
              </label>
              <div className="form-buttons">
                <button
                  type="submit"
                  className="submit-button"
                  disabled={loading}
                >
                  {editingAdmin ? '更新管理员' : '添加管理员'}
                </button>
                <button
                  type="button"
                  onClick={() => setShowAdminModal(false)}
                  className="cancel-button"
                >
                  取消
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminManager;
